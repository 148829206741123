<template>
  <v-layout wrap column>
    <v-flex text-right>
      <v-btn
        v-if="mutable"
        v-show="button"
        color="success"
        @click="addItem"
        :disabled="allowUserToEditOrNot"
        >Add Event</v-btn
      >
      <EventEntry
        v-if="showEventEntry"
        :general-details="generalDetails"
        :edit-event="editEvent"
        :schema="schema"
        @close="onCloseEventEntry"
        @save="onSaveEventEntry"
      />
    </v-flex>
    <v-data-table
      :headers="headers"
      :items="events"
      @click:row="rowClick"
      single-select
      item-key="__ob__.dep.id"
    >
      <template v-slot:item.event="{ item }">
        <v-icon small>
          {{ item.type === "Level" ? "assessment" : "colorize" }}
        </v-icon>
        {{ item.event || item.type }}
      </template>
      <template v-slot:item.time="{item}">
        {{ item.time | formatDate }} <br />
        at {{ item.time | formatTime }}
      </template>
      <template v-slot:item.value="{ item }">
        <EventValue
          :type="item.type"
          :values="item.values"
          :mutable="mutable"
          :allowUserToEditOrNot="allowUserToEditOrNot"
          @edit="onEditEvent(item)"
          @delete="onDeleteEvent(item)"
        />
      </template>
    </v-data-table>
  </v-layout>
</template>

<script>
import EventEntry from "@/new-components/patient/EventEntry";
import EventValue from "@/new-components/patient/EventValue";
import { DateTime } from "luxon";
import { get, cloneDeep } from "lodash";
import config from "../../config/instance";
import momentTz from "moment-timezone";

export default {
  name: "EventTable",

  components: { EventValue, EventEntry },

  props: {
    generalDetails: { type: Object, default: () => {} },
    existingEvents: { type: Array, required: true },
    mutable: { type: Boolean, default: true },
    allowUserToEditOrNot: { type: Boolean, required: false },
    schema: { type: Array, required: true },
  },

  data() {
    return {
      button: true,
      isSelected: false,
      events: this.existingEvents,
      editIndex: -1,
      editEvent: null,
      showEventEntry: false,
      highlightRow: true,
      selectedRow: null,
      headers: [
        {
          text: "Event",
          value: "event",
          align: "left",
          sortable: false,
        },
        {
          text: "Time",
          value: "time",
          align: "right",
          sortable: false,
        },
        {
          text: "Value",
          value: "value",
          align: "right",
          sortable: false,
        },
      ],
    };
  },

  computed: {
    timezone() {
      return get(this.$config, "date_time.events.text", "");
    },
  },

  watch: {
    existingEvents(value) {
      this.events = value;
    },
    events: {
      deep: true,
      handler(value) {
        this.$emit("update:existingEvents", value);
      },
    },
  },

  mounted() {
    this.headers[1].text = this.timezone;
  },

  methods: {
    //  countByType(type) {
    //   let res = {};
    //   this.events.forEach((v) => {
    //     res[v.type] = (res[v.type] || 0) + 1;
    //   });
    //  return isNaN(res[type]) ? 0 : res[type];
    // },
    rowClick: function(item, row) {
      this.selectedRow = row;
      if (!this.highlightRow) {
        row.select(true);
      }
      this.highlightRow = true;
    },

    toggleEdit() {
      this.showEventEntry = false;
      this.editIndex = -1;
      this.editEvent = null;
    },
    addItem() {
      this.toggleEdit();
      this.showEventEntry = true;
    },
    onCloseEventEntry(){
      this.selectedRow.select(false);
        this.toggleEdit();
         this.button = true;
    },
    onSaveEventEntry(event) {
      this.button = true;
      this.isSelected = false;
      // let typeCount = this.countByType(event.type) + 1;
      // console.log(typeCount);
      // event.event = `${event.type} ${typeCount}`;
      if (event) {
        let utcDate = momentTz
          .tz(
            `${event.date}T${event.time}`,
            "YYYY-MM-DD HH:mm:ss",
            config.date_time.events.zone
          )
          .toISOString();
        Object.assign(event, { time: Date.parse(new Date(utcDate)) });
        if (this.editIndex > -1) {
          this.events.splice(this.editIndex, 1, event);
        } else {
          this.events.push(event);
        }
        console.log(utcDate, "utcdate");
      }
      
      this.events.sort(function compare(a, b) {
        var dateA = new Date(a.time);
        var dateB = new Date(b.time);
        return dateA - dateB;
      });
      this.toggleEdit();
    },
    onDeleteEvent(event) {
      const index = this.events.indexOf(event);
      if (confirm("Are you sure you want to delete this item?")) {
        this.events.splice(index, 1);
      }
    },
    onEditEvent(event) {
      this.highlightRow = false;
      this.button = false;
      this.toggleEdit();
      this.editIndex = this.events.indexOf(event);
      const utcdateTime = DateTime.fromMillis(event.time);
      const dateTime = utcdateTime.setZone(config.date_time.events.zone);
      this.editEvent = {
        ...cloneDeep(event),
        date: dateTime.toISODate(),
        time: dateTime.toLocaleString(DateTime.TIME_24_SIMPLE),
        timestamp: event.time,
      };
      this.showEventEntry = true;
    },
  },
};
</script>
<style>
tr.v-data-table__selected {
  background: #ffc7b3 !important;
}
</style>
