<template>
  <v-form v-model="validEvent">
    <v-layout wrap>
      <v-flex px-2 x12 sm4>
        <component
          :is="fields.type"
          :label="fields.label"
          :appendText="fields.unit"
          v-model="event.type"
          :items="fields.items"
          id="Dose Level"
          @input="onChange($event, fields)"
        />
      </v-flex>
      <v-flex px-2 x12 sm4 v-for="field in subFields" :key="field.key">
        <Datepicker
          v-if="field.key == 'date'"
          :is="field.type"
          v-model="event.date"
          :label="field.label"
          :max-date="today"
          :required="field.isRequired"
        />
        <Timepicker v-else-if="field.key == 'time'" v-model="event.time" />
        <component
          v-else
          :is="field.type"
          :label="field.label"
          v-model="event.values[field.key]"
          :appendText="field.unit"
          :items="field.items"
          :validate="validate"
          :required="field.isRequired"
        />
      </v-flex>
      <v-flex x12 xs12>
        <v-btn class="mr-3" color="success" @click="onSave">Save</v-btn>
        <v-btn color="error" @click="$emit('close')" class>Cancel</v-btn>
      </v-flex>
    </v-layout>
  </v-form>
</template>
<script>
import Date from '@/new-components/ui/Datepicker';
import Timepicker from '@/new-components/ui/Timepicker';
import Dropdown from '@/new-components/ui/Dropdown';
import Number from '@/new-components/ui/NumberInput';
import { DateTime } from 'luxon';
import { get } from 'lodash';
// import schema from '../../assets/events';
import config from "../../config/instance";
import _ from "lodash";

export default {
  name: 'EventEntry',
  components: {
    Number,
    Dropdown,
    Date,
    Timepicker,
  },
  props: {
    editEvent: { type: Object, default: null },
    generalDetails: { type: Object, default: null },
    schema: { type: Array, required: true },
  },
  data() {
    return {
      validEvent: false,
      validate: false,
      today: DateTime.local().toISODate(),
      patientDetails: {},
      event: null,
      fields: [],
      subFields: [],
      defaultType: 'Dose',
    };
  },
  computed: {
    height() {
      return get(this.generalDetails, 'height', '');
    },
    weight() {
      return get(this.generalDetails, 'weight', '');
    },
    scr() {
      return get(this.generalDetails, 'scr', '');
    },
  },
  watch: {
    editEvent: {
      immediate: true,
      handler(value) {
        this.event = value || this.defaultItem();
        this.inputChange();
      },
    },
  },
  mounted() {
    this.fields = this.schema[0];
    this.inputChange();
  },
  methods: {
    inputChange() {
     this.subFields = _.find(this.schema[0].items, { value: this.event.type}).field;
    },
    onSave() {
      this.validate = true;
      console.log(this.event);
      if (this.validEvent) {
        this.$emit('save', this.event);
      }
    },
    defaultItem() {
      let dateTime = DateTime.fromObject({
        zone: config.date_time.events.zone
      });
      return {
        values: {
          height: this.height,
          weight: this.weight,
          egfr: '',
          ffm: '',
          scr: this.scr,
          dv: '',
        },
        event: '',
        date: dateTime.toISODate(),
        time: dateTime.toLocaleString(DateTime.TIME_24_SIMPLE),
        type: this.defaultType,
        isInsert: true,
        timestamp: '',
      };
    },
    onChange(value, fields) {
      this.defaultType = value;
      console.log(value);
      if (value == 'Dose') {
        this.subFields = fields.items[0].field;
      } else {
        this.subFields = fields.items[1].field;
      }
    },
  },
};
</script>
