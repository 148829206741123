<template>
  <div>
    <!-- <span v-for="(field, index) in fields" :key="index"> -->
      <div class="title text-capitalize">{{ treatmentName + " PK Parameters"}}</div>
      <v-layout row pa-2>
        <v-flex xs12 sm4 lg4 xl3 pr-4 v-for="(field, index) in fields" :key="index">
          <component
            :is="field.type"
            :value="roundedValue(field.key)"
            :append-text="field.unit"
            :label="field.label"
            :disabled="field.isReadonly"
          />
        </v-flex>
      </v-layout>
    <!-- </span> -->
  </div>
</template>

<script>
import { Main } from "@/services/modules";
import { get } from "lodash";
import Number from "@/new-components/ui/NumberInput";
// import { Http } from "@/services/service";
import config from '../../config/instance';

export default {
  name: "PKParameters",
  components: { Number },
  props: {
    schema: { type: Array, required: true },
    pkParameters: { type: Object, required: true }
  },

  computed: {
  },
   created() {
  },
  data() {
    return {
      treatmentName: "",
      fields: [],
      rounded: [],
      configDefault:true
    };
  },
  mounted() {
   if (config.ehr || this.configDefault) {
      const { drugname } = this.$route.query;
      //  console.log(this.$route,"this.$route PK");
      this.getTreatment(drugname);
      // console.log(drugname,"drugname is");
      this.fields = this.schema;
    } else {
       const { treatment } = this.$route.params;
       this.getTreatment(treatment);
       this.fields = this.schema;
    }
  },

  methods: {
    async getTreatment(treatment) {
      try {
        const resp = await Main.getTreatment(treatment);
        this.treatmentName = get(resp, "data.name", "");
      } catch (error) {
        this.setSnack({
          message: error.response.data.message,
          color: "error"
        });
        this.$store.commit("snackbar/setSnack", {
          message: error.response.data.message,
          color: "error"
        });
        if (error.response.data.code === 404) {
          this.$router.push({ name: "Login" });
        }
      }
    },
      roundedValue(key) {
        return get(this.pkParameters, key, 0).toFixed(2);
    },
  }
};
</script>
