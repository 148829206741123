<template>
  <div class="py-3">
    <v-btn
      color="primary"
      outlined
      :disabled="allowUserToEditOrNot || editMode"
      @click="$emit('modifyDose')"
      >Modify Dose</v-btn
    >
    <div v-show="editMode" class="py-3">
      <div class="title">Dose Details</div>
      <DosageInfo
        is-suggested
        :general-details="generalDetails"
        :dose-details="suggestedDoseDetails"
        v-on="$listeners"
      />
      <v-form v-model="formValid">
        <v-layout row pa-4>
          <v-flex
            xs12
            sm4
            lg4
            xl3
            pr-4
            v-for="(field, index) in allFields"
            :key="index"
          >
            <component
              v-model="doseDetails[field.key]"
              :is="field.type"
              :items="field.items"
              :allowUserToEditOrNot="allowUserToEditOrNot"
              :disabled="field.isReadonly || allowUserToEditOrNot"
              :validate="validate"
              :appendText="field.unit"
              :label="field.label"
              :field-key="field.key"
              :required="field.isRequired"
              :message="field.message"
              :error-message="field.errorMessage"
              @change="onChange($event, field)"
              :commonFunction="commonFunction"
            />
          </v-flex>
        </v-layout>
      </v-form>
      <hr />
      <v-btn color="primary" :loading="saving" @click="onRecalculate"
        >Recalculate Metrics</v-btn
      >
    </div>
  </div>
</template>
<script>
import DosageInfo from "@/new-components/patient/DosageInfo";
import NumberInput from "@/new-components/ui/NumberInput";
import Dropdown from "@/new-components/ui/Dropdown";
import Number from "@/new-components/ui/NumberInput";
export default {
  name: "ModifyDosage",
  components: { Dropdown, DosageInfo, NumberInput, Number },
  props: {
    schema: { type: Array, required: true },
    generalDetails: { type: Object, required: true },
    doseDetails: { type: Object, required: true },
    suggestedDoseDetails: { type: Object, required: true },
    enableNavigation: { type: Boolean, required: true },
    saving: { type: Boolean, required: true },
    allowUserToEditOrNot: { type: Boolean, required: true }
  },
  data() {
    return {
      validate: false,
      allFields: [],
      formValid: false
    };
  },
  computed: {
    editMode() {
      return !this.enableNavigation;
    },
    dailyDose() {
      return this.dosage(this.doseDetails);
    }
  },
  mounted() {
    this.allFields = this.schema;
  },
  methods: {
    onRecalculate() {
      this.validate = true;
      this.$emit("recalculate", this.formValid);
    },
    async action(v, key) {
      const valid = v.fields.every(
        field =>
          this.doseDetails[field] != undefined && this.doseDetails[field] != ""
      );
      if (valid) {
        this.commonFunction(v, key, "doseRecommendation");
      }
    },

    async onChange(value, field) {
      if (value) {
        if (field.validator != undefined) {
          field.validator.map(valid => {
            if (valid.type == "dependent") {
              valid.fields.map(field => {
                this.allFields.map(f => {
                  if (f.key == field) {
                    f.validator.map(v => {
                      if (v.type == "internal" || v.type == "external") {
                        this.commonFunction(v, field, "doseRecommendation");
                      }
                    });
                  } else {
                    if (f.validator != undefined) {
                      f.validator.map(v => {
                        if (v.type == "internal" || v.type == "external") {
                          console.log(v);
                          setTimeout(() => { this.commonFunction(v, f.key, "doseRecommendation"); }, 500)
                        }
                      });
                    }
                  }
                });
              });
            } else {
              if (valid.type == "external") {
                this.commonFunction(valid, field, "doseRecommendation");
              }
            }
          });
        }
      }
    },
    commonFunction(v, key, type) {
      this.$emit("commonFunction", v, key, type);
    }
  }
};
</script>
<style scoped>
hr {
  opacity: 0.1;
}
</style>
